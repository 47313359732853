.html,
body {
  font-size: 16px;
  background-color: #2d78a0;
}
.bg {
  background-color: #2d78a0;
  border-radius: 5px;
}
.search_icon {
  color: grey;
  cursor: pointer;
}
.home_icon {
  color: #fff;
  cursor: pointer;
}
.btn {
  padding: 0.5rem 2rem;
}
.btn-secondary {
  background-color: #b3b2b2;
  border-color: #b3b2b2;
  color: #fff;
}
.btn-secondary:hover {
  background-color: #b3b2b2;
  border-color: #b3b2b2;
}

.btn-primary {
  background-color: #2d78a0;
  border-color: #2d78a0;
  color: #fff;
}
.btn-primary:hover {
  background-color: #2d78a0;
  border-color: #2d78a0;
}
.btn-pass {
  background-color: #fff;
  border: 2px solid #9f552d;
  border-radius: 10px;
  color: #9f552d;
}
.btn-pursue {
  background-color: #fff;
  border: 2px solid #779f2d;
  border-radius: 10px;
  color: #779f2d;
}
.btn-postpone {
  background-color: #fff;
  border: 2px solid #9f8e2d;
  border-radius: 10px;
  color: #9f8e2d;
}
.btn-passed {
  background-color: #9f552d;
  border: 2px solid #9f552d;
  border-radius: 10px;
  color: #ffffff;
}
.btn-pursued {
  background-color: #779f2d;
  border: 2px solid #779f2d;
  border-radius: 10px;
  color: #ffffff;
}
.btn-postponed {
  background-color: #9f8e2d;
  border: 2px solid #9f8e2d;
  border-radius: 10px;
  color: #ffffff;
}
.span-console {
  border-color: #2d78a0;
  border: 2px solid #2d78a0;
  border-radius: 10px;
  color: #fff;
  padding: 10px;
}
.lnk-primary {
  background-color: #2d78a0;
  border-color: #2d78a0;
  color: #fff !important;
  text-align: center;
  text-decoration-line: underline !important;
}

.text-primary {
  color: #2d78a0 !important;
}
.border-bg {
  border: 1px solid #b4b4b4;
  border-radius: 5px;
}
.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  color: #fff;
  border-bottom: 5px solid #78c3f9;
  background: transparent;
}
/* .nav-tabs .nav-link:focus, .nav-tabs .nav-link:hover {
  border-color: transparent;
} */
.nav-tabs .nav-link {
  border: none;
  color: #fff;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
}
.nav-tabs {
  border-bottom: none;
  background-color: #2d78a0;
}
.nav-tabs .nav-item {
  border-bottom: 1px solid transparent;
}
.border-card {
  background: #fff;
  border: 1px solid #e0e0e0;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
}
.form-text {
  color: #2d78a0;
}
.account_img {
  max-width: 300px;
  height: auto;
}
.bg-grey {
  background-color: rgb(238 238 238);
}

.bg-grey a {
  color: #2d78a0;
}
.bg-grey a:hover {
  color: #2d78a0;
  background: #e5e5e5;
}
.card-title {
  border-bottom: 1px solid #e0e0e0;
  background: #2d78a0;
  color: #fff;
  font-weight: bold;
}
.card-item {
  padding: 0.7rem;
  border-bottom: 1px solid #e0e0e0;
}
.card-text {
  color: #2d78a0;
  font-weight: bold;
}
.sidebar-list a {
  color: #2d78a0;
  border-left: 1px solid #e0e0e0;
  padding: 0.5rem;
}
.sidebar-list {
  display: flex;
}

.sidebar-list a:hover {
  color: #2d78a0;
}
.sidebar-topics a {
  color: #2d78a0;
}

.sidebar-topics a:hover {
  color: #2d78a0;
}
.side-menus {
  padding: 0.8rem;
  color: #2d78a0;
}
.side-menus a {
  color: #2d78a0;
  text-decoration: none;
}
.side-menus:hover {
  background-color: #e0e0e0;
}
.google-btn {
  height: 45px;
  width: 143px;
}
table {
  overflow: hidden;
}
.table_gen > tr {
  padding: 8px;
}

.btn-pagination {
  padding: 0.25rem 0.5rem;
}
.btn-ad {
  font-size: 0.8rem;
}
.account_img {
  max-width: 255px;
}
#nprogress .bar {
  background: #2d78a0 !important;
}

.btn-width {
  width: 150px;
}

.btn-outline-primary {
  color: #fff;
  border-color: #78c3f9;
}

.btn-outline-primary:hover {
  color: #fff;
  background-color: #78c3f9;
  border-color: #78c3f9;
}

.btn-outline-primary:not(:disabled):not(.disabled):active,
.show > .btn-outline-primary.dropdown-toggle {
  color: #fff;
  background-color: #78c3f9;
  border-color: #78c3f9;
}

.dropdown-toggle::after {
  display: none;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}

.dropdown_ref {
  display: inline;
}

.btn-secondary:not(:disabled):not(.disabled).active,
.btn-secondary:not(:disabled):not(.disabled):active,
.show > .btn-secondary.dropdown-toggle {
  color: #fff;
  background-color: #2d78a0;
  border-color: #2d78a0;
}

#nprogress .peg {
  box-shadow: 0 0 10px #2d78a0, 0 0 5px#2d78a0 !important;
}

#nprogress .spinner-icon {
  border-top-color: #2d78a0 !important;
  border-left-color: #2d78a0 !important;
}

.login_btn .btn-primary.focus,
.btn-primary:focus {
  box-shadow: none;
}
.login_btn .btn-primary.focus,
.login_btn .btn-primary:focus,
.login_btn .btn-primary:hover {
  color: #fff;
  background-color: transparent;
  border-color: transparent;
}
.login_btn .btn-primary:not(:disabled):not(.disabled).active:focus,
.btn-primary:not(:disabled):not(.disabled):active:focus,
.show > .btn-primary.dropdown-toggle:focus {
  box-shadow: none;
}

.login_btn .btn-primary:not(:disabled):not(.disabled).active,
.login_btn .btn-primary:not(:disabled):not(.disabled):active,
.show > .login_btn .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: transparent;
  border-color: transparent;
}

.table td,
.table th {
  padding: 0.1rem;
  vertical-align: top;
  border-top: 1px solid #dee2e6;
  font-size: 16px;
  white-space: nowrap;
}

.Toastify__toast--success {
  background: #2d78a0;
}

.Toastify__toast--error {
  background: #9f2d3e;
}

.table_font_size {
  font-size: 16px;
}

.grey-bg {
  background-color: #f7f7f7;
  border-color: rgb(75, 75, 75);
  border-radius: 5px;
}

.contact-card {
  border: 1px solid #000;
  background-color: #fff;
  border-radius: 5px;
}

.link-pointer {
  cursor: pointer;
}
.css-2b097c-container-organizatoin{
  width: 970px !important;
}
.text-muted-break {
  word-break: break-all;
}
.border-card-tynax-contact {
  background: #fff;
  border: 1px solid #e0e0e0;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
  width: 200px;
}
.table-contact {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 50%;
}
.table-contact-row {
  border: 3px solid #dddddd;
  text-align: left;
  padding: 8px;
}
@media (min-width: 991px) {
  .base-container {
    width: 100%;
    max-width: 90vw;
    margin: 0 auto;
  }
}
